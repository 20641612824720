import { ApiRequest, Reducer } from 'store';

export const initialState = {
    packages: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
} as const;

export type ContractPackagesState = typeof initialState;

export const ContractPackagesReducer: Reducer<ContractPackagesState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CONTRACT_PACKAGES/FETCH_PACKAGES':
            return {
                ...state,
                packages: {
                    ...state.packages,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@CONTRACT_PACKAGES/FETCH_PACKAGES_SUCCESS':
            return {
                ...state,
                packages: {
                    ...state.packages,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.natureIds,
                },
            };

        case '@CONTRACT_PACKAGES/FETCH_PACKAGES_FAILURE':
            return {
                ...state,
                packages: {
                    ...state.packages,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
