import { ApiRequest, Reducer } from 'store';

export interface IClientsRequestMap {
    readonly [icId: string]: ApiRequest;
}
export const initialState = {
    clients: {} as IClientsRequestMap,
    relationships: {} as { [bdrId: string]: ApiRequest },
} as const;

export type ClientsState = typeof initialState;

export const ClientsReducer: Reducer<ClientsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CLIENTS/FETCH_CLIENTS':
            return {
                ...state,
                clients: createFetchClientsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@CLIENTS/FETCH_CLIENTS_SUCCESS':
            return {
                ...state,
                clients: createFetchClientsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@CLIENTS/FETCH_CLIENTS_FAILURE':
            return {
                ...state,
                clients: createFetchClientsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@CLIENTS/FETCH_CLIENT_RELATIONSHIP':
            return {
                ...state,
                relationships: {
                    ...state.relationships,
                    [action.payload.bdrId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@CLIENTS/FETCH_CLIENT_RELATIONSHIP_SUCCESS':
            return {
                ...state,
                relationships: {
                    ...state.relationships,
                    [action.payload.bdrId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@CLIENTS/FETCH_CLIENT_RELATIONSHIP_FAILURE':
            return {
                ...state,
                relationships: {
                    ...state.relationships,
                    [action.payload.bdrId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};

const createFetchClientsState = (state: ClientsState, icIds: string[], apiRequest: ApiRequest) => {
    let stateClients = {
        ...state.clients,
    };
    for (const icId of icIds) {
        stateClients = {
            ...stateClients,
            [icId]: {
                ...apiRequest,
            },
        };
    }
    return stateClients as IClientsRequestMap;
};