import { ContractFull, FileParameter, SearchContractRequest } from 'services/ApiService/ResearchContract/ResearchContractApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_CONTRACTS_TAKE = 20;
export const DEFAULT_SEARCH_CONTRACTS_FILTERS = {
    skip: 0,
    take: DEFAULT_SEARCH_CONTRACTS_TAKE,
};
export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_CONTRACTS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & {
        filters: SearchContractRequest,
        total: number,
    },
    file: {} as FileParameter,
    fullContracts: {} as { [id: string]: ApiRequest<ContractFull> },
    creation: {} as { readonly [name: string]: boolean },
    update: {} as { readonly [contractId: string]: boolean },
    activate: {} as { readonly [contractId: string]: boolean },
    close: {} as { readonly [contractId: string]: boolean },
} as const;

export type ContractState = typeof initialState;

export const ContractReducer: Reducer<ContractState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CONTRACTS/SET_SEARCH_TERMS':
            if (state.search.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state?.search.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_CONTRACT_TYPES':
            const sortedContractTypes = action.payload.contractTypes?.sort((a, b) => !(a && b) ? 0 : a.localeCompare(b));
            if (state.search.filters.contractTypes?.join(',') === sortedContractTypes?.join(',')
            ) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state?.search.filters,
                        contractTypes: sortedContractTypes,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CLIENT_IC_IDS':
            const sortedClientIcIds = action.payload.clientIcIds?.sort((a, b) => !(a && b) ? 0 : a.localeCompare(b));
            if (state.search.filters.clientIcIds?.join(',') === sortedClientIcIds?.join(',')
            ) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state?.search.filters,
                        clientIcIds: sortedClientIcIds,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };
            
        case '@CONTRACTS/SET_SEARCH_CONTACT_IC_IDS':
            const sortedContactIcIds = action.payload.contactIcIds?.sort((a, b) => !(a && b) ? 0 : a.localeCompare(b));
            if (state.search.filters.contactIcIds?.join(',') === sortedContactIcIds?.join(',')
            ) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state?.search.filters,
                        contactIcIds: sortedContactIcIds,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_STATUS':
            const sortedStatus = action.payload.statuses?.sort((a, b) => !(a && b) ? 0 : a.localeCompare(b));
            if (state.search.filters.statuses?.join(',') === sortedStatus?.join(',')
            ) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state?.search.filters,
                        statuses: sortedStatus,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip
                && state.search.filters.take === action.payload.take
            ) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/FETCH_SEARCH':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@CONTRACTS/FETCH_SEARCH_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.contractIds,
                    total: action.payload.total,
                },
            };

        case '@CONTRACTS/FETCH_SEARCH_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: false,
                    didInvalidate: true,
                    data: undefined,
                },
            };

        case '@CONTRACTS/FETCH_CONTRACT_BY_ID':
            return {
                ...state,
                fullContracts: {
                    ...state.fullContracts,
                    [action.payload.contractId]: {
                        ...state.fullContracts[action.payload.contractId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@CONTRACTS/FETCH_CONTRACT_BY_ID_SUCCESS':
            return {
                ...state,
                fullContracts: {
                    ...state.fullContracts,
                    [action.payload.contractId]: {
                        ...state.fullContracts[action.payload.contractId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@CONTRACTS/FETCH_CONTRACT_BY_ID_FAILURE':
            return {
                ...state,
                fullContracts: {
                    ...state.fullContracts,
                    [action.payload.contractId]: {
                        ...state.fullContracts[action.payload.contractId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        
        case '@CONTRACTS/CREATE_CONTRACT':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: true,
                },
            };

        case '@CONTRACTS/CREATE_CONTRACT_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                creation: {
                    ...state.creation,
                    [action.payload.name]: false,
                },
            };

        case '@CONTRACTS/CREATE_CONTRACT_FAILURE':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: false,
                },
            };
        
        case '@CONTRACTS/SET_FILE':
            return {
                ...state,
                file: action.payload.file,
            };

        case '@CONTRACTS/CLEAR_FILE':
            return {
                ...state,
                file: {} as FileParameter,
            };


        case '@CONTRACTS/UPDATE_CONTRACT':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.contractId]: true,
                },
            };

        case '@CONTRACTS/UPDATE_CONTRACT_SUCCESS':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.contractId]: false,
                },
                fullContracts:{
                    ...state.fullContracts,
                    [action.payload.contractId]: {
                        ...state.fullContracts[action.payload.contractId],
                        didInvalidate: true,
                    },
                },
            };

        case '@CONTRACTS/UPDATE_CONTRACT_FAILURE':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.contractId]: false,
                },
            };

        case '@CONTRACTS/ACTIVATE_CONTRACT':
            return {
                ...state,
                activate: {
                    ...state.activate,
                    [action.payload.contractId]: true,
                },
            };

        case '@CONTRACTS/ACTIVATE_CONTRACT_SUCCESS':
            return {
                ...state,
                activate: {
                    ...state.activate,
                    [action.payload.contractId]: false,
                },
                fullContracts: {
                    ...state.fullContracts,
                    [action.payload.contractId]: {
                        ...state.fullContracts[action.payload.contractId],
                        didInvalidate: true,
                    },
                },
            };

        case '@CONTRACTS/ACTIVATE_CONTRACT_FAILURE':
            return {
                ...state,
                activate: {
                    ...state.activate,
                    [action.payload.contractId]: false,
                },
            };

        case '@CONTRACTS/CLOSE_CONTRACT':
            return {
                ...state,
                close: {
                    ...state.close,
                    [action.payload.contractId]: true,
                },
            };

        case '@CONTRACTS/CLOSE_CONTRACT_SUCCESS':
            return {
                ...state,
                close: {
                    ...state.close,
                    [action.payload.contractId]: false,
                },
                fullContracts: {
                    ...state.fullContracts,
                    [action.payload.contractId]: {
                        ...state.fullContracts[action.payload.contractId],
                        didInvalidate: true,
                    },
                },
            };

        case '@CONTRACTS/CLOSE_CONTRACT_FAILURE':
            return {
                ...state,
                close: {
                    ...state.close,
                    [action.payload.contractId]: false,
                },
            };

        default:
            return state;
    }
};