import {
    LoggedUser,
} from './ApiService/ResearchContract/ResearchContractApiClient';

export enum Permissions {
    Administrator = 'Administrator',
    SGM_SUBSCRIBE = 'SGM_SUBSCRIBE',
    VIEW_ALL_CONTRACT_INFO = 'View_all_contract_info',
    VIEW_CONTRACT_ON_COVERAGE = 'View_contract_on_coverage',
    CONTRACT_MANAGER = 'Contract_Manager'
}

const convertUserPermissions = (user?: LoggedUser | null): Permissions[] => {
    if (!user) {
        return [];
    }

    if (user.permissions && user.permissions.length) {
        return user.permissions.map((up) => up as Permissions);
    }
    return [];
};

export const hasRight = (user?: LoggedUser | null, permission?: Permissions): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return !!userPermissions.find((up) => up === permission);
    }
    return false;
};

export const hasAnyRight = (user: LoggedUser | null | undefined, permissions: Permissions[]): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return !!userPermissions.some((up) => permissions.includes(up));
    }
    return false;
};

export const hasAccess = (user: LoggedUser | null | undefined) => {
    return hasAnyRight(user, Object.values(Permissions));
};