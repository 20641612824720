import { ApiRequest, Reducer } from 'store';

export interface IPersonsRequestMap {
    readonly [icId: string]: ApiRequest;
}
export const initialState = {
    persons: {} as IPersonsRequestMap,
} as const;

export type PersonsState = typeof initialState;

export const PersonsReducer: Reducer<PersonsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@PERSONS/FETCH_PERSONS':
            return {
                ...state,
                persons: createFetchPersonsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PERSONS/FETCH_PERSONS_SUCCESS':
            return {
                ...state,
                persons: createFetchPersonsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@PERSONS/FETCH_PERSONS_FAILURE':
            return {
                ...state,
                persons: createFetchPersonsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };
        default:
            return state;
    }
};

const createFetchPersonsState = (state: PersonsState, icIds: string[], apiRequest: ApiRequest) => {
    let statePersons = {
        ...state.persons,
    };
    for (const icId of icIds) {
        statePersons = {
            ...statePersons,
            [icId]: {
                ...apiRequest,
            },
        };
    }
    return statePersons as IPersonsRequestMap;
};