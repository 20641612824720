import { useAppContext } from 'components/common/AppProvider';
import { ServiceLoader } from 'components/common/Loading';
import React, { useEffect } from 'react';
import { Permissions, hasAccess, hasRight } from 'services/RightsService';
import { sgConnect, redirectUnauthorizedUser } from 'services/SgConnect';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { ResearchContractSelectors } from 'store/Normalizr/ResearchContractSelectors';

export const ProtectedRoute: React.FC<{ children: JSX.Element, permission?: Permissions }> = ({ children, permission }) => {
    const { dispatch, state: { appContext: { loggedUserId, isFetching }, entities } } = useAppContext();
    const loggedUser = ResearchContractSelectors.getLoggedUser(loggedUserId, entities.researchContract);

    useEffect(() => {
        (async () => {
            if (!sgConnect?.isAuthorized()) {
                sgConnect?.requestAuthorization();
            }
            else {
                try {
                    const appContext = await dispatch(getAppContext());
                    if (!hasAccess(appContext?.loggedUser)) {
                        redirectUnauthorizedUser();
                    }
                } catch (error) {
                    redirectUnauthorizedUser();
                }
            }
        })();
    }, []);

    if (isFetching) {
        return <div className="d-flex justify-content-center"><ServiceLoader /></div>;
    }

    return hasAccess(loggedUser) && (!permission || hasRight(loggedUser, permission))
        ? children
        : null;
};
