import { ApiRequest, Reducer } from 'store';

export const initialState = {
    categories: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
} as const;

export type ReferentialsState = typeof initialState;

export const ReferentialsReducer: Reducer<ReferentialsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@REFERENTIALS/FETCH_CATEGORIES':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIALS/FETCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.natureIds,
                },
            };

        case '@REFERENTIALS/FETCH_CATEGORIES_FAILURE':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
